import {ref} from "vue"
import store from '@/store'
import {toastController} from '@ionic/vue';

const data = ref<any>(null)

export default function RankingService () {

    const perPage = ref(25)
    const totalUsers = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('codice')
    const isSortDirDesc = ref(true)

    const userTotalcoins = ref(0)
    const userTotalmatches = ref(0)

    async function  openToastOptions(data, color = 'success') {
        const toast = await toastController.create({
            color: color,
            duration: 2000,
            message: data,
        });
        await toast.present();
    }

    const fetchRanking = () => {
        store
            .dispatch('ranking/fetchRanking', {
                "class_id": JSON.parse(localStorage.getItem('currentClass') || '{}').id
            })
            .catch(error => {
                console.log(error.response)
                if (error.response.status != 422) {
                    openToastOptions('Impossibile caricare il ranking', 'danger')
                }
            })
    }

    const getRanking = () => store.getters['ranking/getRanking']

    return {
        data,
        getRanking,
        fetchRanking,

        perPage,
        currentPage,
        totalUsers,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        userTotalcoins,
        userTotalmatches,
    }
}
